import * as React from "react"
import { useEffect } from "react";

const SnkrsRedirect = () => {
    useEffect(
        () => {
            const windowUrl = window.location.search;
            const params = new URLSearchParams(windowUrl);

            window.location.assign('snkrs://story/' + params.get('id'));
        }
    )

    return(
        <></>
    )
}

export default SnkrsRedirect;